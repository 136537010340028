import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import classNames from "classnames";

import { isMobile } from "lib/utils";

const SelectHiddenInput = ({ values, name, multi }) => {
  // If no values are present, we need to render an empty input so Rails can process
  // it as a blank value.
  if (!values || values.length === 0) {
    return <input type="hidden" name={name} value="" />;
  }

  if (multi) {
    return values.map((value, idx) => (
      <input
        key={`input-${idx}`}
        type="hidden"
        name={name}
        value={value.value}
      />
    ));
  }

  return <input type="hidden" name={name} value={values.value} />;
};

class SelectInput extends Component {
  state = {
    values: this.props.values ? this.props.values : this.props.defaultValues,
  };

  getValues = () =>
    this.props.values !== undefined ? this.props.values : this.state.values;

  setValues = values => {
    this.setState({ values });
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  };

  clearValues = () => this.setValues([]);

  render() {
    const {
      name,
      options,
      multi,
      className,
      hasErrors,
      value,
      onFocus,
      onBlur,
      isDisabled,
      placeholder,
      id,
      isClearable,
      isLoading,
    } = this.props;
    const values = this.getValues();
    const classnames = classNames(className, {
      "react-select-with-errors": hasErrors,
    });
    const closeMenuOnSelect = !multi && !isMobile();

    return (
      <Fragment>
        <SelectHiddenInput values={values} name={name} multi={multi} />
        <ReactSelect
          classNamePrefix="react-select"
          isClearable={isClearable}
          className={classnames}
          placeholder={placeholder || "Seleccionar"}
          options={options}
          onChange={this.setValues}
          defaultValue={value}
          value={values}
          joinValues
          isMulti={multi}
          isSearchable={multi}
          onFocus={onFocus}
          onBlur={onBlur}
          closeMenuOnSelect={closeMenuOnSelect}
          isDisabled={isDisabled}
          id={id} // this is a workaround to test our selects, we must find a better way
          name={id}
          inputId={id}
          isLoading={isLoading}
        />
      </Fragment>
    );
  }
}

SelectInput.propTypes = {
  /** Available options **/
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,

  className: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,

  /** Selected value(s). Pass this if you want to control the component **/
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),

  /** Initial selected value(s). Pass this if you want this component to be uncontrolled **/
  defaultValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),

  /** Name that the input(s) that hold the values will have **/
  name: PropTypes.string,

  /** If the input has any error. If so the input will have a redish border **/
  hasErrors: PropTypes.bool,

  /** If the input is multi or not **/
  multi: PropTypes.bool,
};

SelectHiddenInput.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  multi: PropTypes.bool,
};

export default SelectInput;
