import React from "react";
import ReactDOM from "react-dom";

import SelectInput from "lib/forms/SelectInput";

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("[data-react-select-input]").forEach((el) => {
    const options = JSON.parse(el.dataset.reactSelectOptions);
    const values = JSON.parse(el.dataset.reactSelectValues);
    const name = el.dataset.reactSelectName;
    const hasErrors = !!el.dataset.reactSelectErrors;

    ReactDOM.render(
      <SelectInput
        name={name}
        options={options}
        defaultValues={values}
        multi={!!el.dataset.reactSelectMulti}
        hasErrors={hasErrors}
      />,
      el
    );
  });
});
