import classNames from "classnames";

const DESKTOP_BREAKPOINT = 1140;

export const isMobile = () => {
  return window.innerWidth <= DESKTOP_BREAKPOINT;
};

export const truncateWithRemaining = (array, maxLength) => {
  if (array.length <= maxLength) {
    return { items: array };
  }

  return {
    items: array.slice(0, maxLength),
    remainingItemsCount: array.length - maxLength,
  };
};

export const truncateStringArray = (array, maxLength, joinWith = "+") => {
  const { items, remainingItemsCount } = truncateWithRemaining(
    array,
    maxLength
  );

  if (!remainingItemsCount) return items;

  return items.concat(`${joinWith}${remainingItemsCount}`);
};

export const truncateString = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const truncatedString = text.slice(0, maxLength);

  return `${truncatedString}...`;
};

export const randomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export const shuffleArray = (array) => {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = randomInt(i + 1);
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }

  return arrayCopy;
};

/**
 * Returns an obj that allows to iterate in cycles over an Array.
 * TODO: Consider raising an error if the array is empty
 * @param {Array} array - array to cycle
 * @param {number} [initialIndex] - initial index that the cycle will start from. Defaults to 0
 * @returns {getCurrent, getNext}
 */
export const createCycle = (array, initialIndex = 0) => {
  let currentIndex = initialIndex;

  return {
    /**
     * Returns the current element of the cycle. This doesn't move the cycle to the enxt element
     * @returns {*}
     */
    getCurrent() {
      return array[currentIndex];
    },

    /**
     * Returns the next element on the cycle. This moves the cycle to the next element.
     * @returns {*}
     */
    getNext() {
      currentIndex = (currentIndex + 1) % array.length;
      return array[currentIndex];
    },
  };
};

export const isString = (variable) =>
  typeof variable === "string" || variable instanceof String;

export const isValidMobilePhoneNumber = (mobilePhone) => {
  const phoneReg = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;
  return phoneReg.test(mobilePhone);
};

export const isValidZipCode = (zipCode) => {
  const zipCodeReg = /^\d{5}(?:[\s-]\d{4})?$/i;
  return zipCodeReg.test(zipCode);
};

export const isPlainObject = (target) =>
  typeof target == "object" && target !== null && target.constructor === Object;
/**
 * Returns a deep copy from a plain object
 */
export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const addActiveClass = (className, isActive) =>
  classNames(className, { [`${className}--active`]: isActive });

export const identity = (arg) => arg;

export const camelCase = (str) =>
  str.toLowerCase().replace(/[^\dA-Za-z]+(.)/g, (m, chr) => chr.toUpperCase());

export const snakeCase = (str) => {
  let upperChars = str.match(/([A-Z])/g);
  if (!upperChars) {
    return str;
  }
  for (let i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(
      new RegExp(upperChars[i]),
      "_" + upperChars[i].toLowerCase()
    );
  }
  if (str.slice(0, 1) === "_") {
    str = str.slice(1);
  }
  return str;
};

const mapKeys = (obj, func) =>
  Object.entries(obj).reduce((newObj, [key, value]) => {
    newObj[func(key, value)] = value;
    return newObj;
  }, {});

const mapValues = (obj, func) =>
  Object.entries(obj).reduce((newObj, [key, value]) => {
    newObj[key] = func(key, value);
    return newObj;
  }, {});

const createTransformObjKeys = (transformer) => {
  const transformerObjKeys = (obj) => {
    if (isPlainObject(obj)) {
      const newObj = mapValues(obj, (_, value) => transformerObjKeys(value));
      return mapKeys(newObj, transformer);
    }

    if (Array.isArray(obj)) {
      return obj.map(transformerObjKeys);
    }

    return obj;
  };
  return transformerObjKeys;
};

export const camelCaseObjKeys = createTransformObjKeys(camelCase);
export const snakeCaseObjKeys = createTransformObjKeys(snakeCase);
